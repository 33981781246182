import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';

import { nativePostMessage } from '../../../common/components/NativeInterface';
import { sessionActions } from '../../../store';

import { ReactComponent as UserIcon } from '../../../resources/images/user-dropdown/user-icon.svg';
import { ReactComponent as BellIcon } from '../../../resources/images/user-dropdown/bell-icon.svg';
import { ReactComponent as DeviceIcon } from '../../../resources/images/user-dropdown/device-icon.svg';
import { ReactComponent as GeofenceIcon } from '../../../resources/images/user-dropdown/geofence-icon.svg';
import { ReactComponent as OrderIcon } from '../../../resources/images/user-dropdown/order-icon.svg';
import { ReactComponent as LoginICon } from '../../../resources/images/user-dropdown/login.svg';
import { useAdministrator } from '../../../common/util/permissions';
import customFetch from '../../../common/util/customFetch';

const UserMenu = ({ open, handleClose }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const administrator = useAdministrator();
  const user = useSelector((state) => state.session.user);
  const path = location?.pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
    return null;
  }, [open, dropdownRef, handleClose]);

  const handleLogout = async () => {
    window.localStorage.removeItem('wasFilterOpenedOnLogin');
    window.localStorage.removeItem('nonActiveTrackerModel');
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      window.localStorage.setItem('modalShow', false);
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await customFetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }
    window.localStorage.removeItem('filter');
    window.localStorage.removeItem('filterSort');
    await customFetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    window.location = '/';
    localStorage.removeItem('auth-token');
    dispatch(sessionActions.updateAuthToken(null));
    dispatch(sessionActions.updateUser(null));
  };
  const currentUser = useSelector((state) => state.session.user);

  const profileLink = administrator ? `/settings/user/${user.id}` : '/settings/profile';
  const notificationsLink = administrator ? '/reports/event' : '/settings/alerts?viewAll=1';
  const devicesLink = administrator ? '/settings/devices' : '/devices';
  const ordersLink = administrator ? '/settings/all-orders' : '/settings/orders';
  // const getUserType = (user) => (user ? (user.administrator ? 'Admin' : (user.superAdministrator ? 'Super Admin' : 'User')) : '');
  if (open) {
    return (
      <div className="user-menu" ref={dropdownRef}>
        <div className="user-menu__container">
          <div className="user-menu__container__header">
            <div className="user-info">
              <img src={currentUser.profileImageUrl ? `${process.env.REACT_APP_BUCKET_URL}/${currentUser.profileImageUrl}` : '/default-dp.png'} alt="user-pic" />
              <p>{user?.fullName}</p>
            </div>
            {/* <Button variant="container">{getUserType(user)}</Button> */}
          </div>
          <div className="divider" />
          <div className="user-menu__container__body">
            <p className="title">Account</p>
            <ul className="list">
              <li className={path === '/settings/profile' || path === `/settings/user/${user.id}` ? 'active' : ''}>
                <Link to={profileLink} onClick={handleClose}>
                  <UserIcon />
                  {' '}
                  <span>Profile</span>
                </Link>
              </li>
              <li className={path === '/settings/devices' || path === '/devices' ? 'active' : ''}>
                <Link to={devicesLink} onClick={handleClose}>
                  <DeviceIcon />
                  <span>{administrator ? 'Devices' : 'Dashboard'}</span>
                </Link>
              </li>
              <li className={path === '/reports/event' || path === '/notifications' ? 'active' : ''}>
                <Link to={notificationsLink} onClick={handleClose}>
                  <BellIcon />
                  {' '}
                  <span>Notifications</span>
                </Link>
              </li>
              <li className={path === '/geofences' ? 'active' : ''}>
                <Link to="/geofences" onClick={handleClose}>
                  <GeofenceIcon />
                  <span>Geofence</span>
                </Link>
              </li>
              { administrator && (
                <li className={path === '/settings/users' ? 'active' : ''}>
                  <Link to="/settings/users" onClick={handleClose}>
                    <UserIcon />
                    {' '}
                    <span>Users</span>
                  </Link>

                </li>
              )}
              <li className={path === '/settings/all-orders' || path === '/settings/orders' ? 'active' : ''}>
                <Link to={ordersLink} onClick={handleClose}>
                  <OrderIcon />
                  <span>Orders</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="divider" />
          <div className="user-menu__container__footer">
            <Button className="logout-btn" onClick={handleLogout}>
              <LoginICon />
              {' '}
              <span>Logout</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default UserMenu;
