import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga';
import Button from '@mui/material/Button';
import AlertMessage from '../../../common/components/AlertMessage';
import { validateEmail } from '../../../common/util/validations';
import customFetch from '../../../common/util/customFetch';

const EmailInput = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const handleNewsletter = async () => {
    const response = await customFetch('/api/newsletter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      ReactGA.event({
        category: 'newsletter_signup',
        action: 'Newsletter Signup',
        value: email,
        lifecycle_stage: 'subscriber',
      });
      setEmail('');
      setShowMessage(true);
    }
  };

  const validateandSetEmail = (email) => {
    setEmail(email);
    if (validateEmail(email)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="email-input">
        <input type="email" placeholder="Enter email" value={email} onChange={(event) => validateandSetEmail(event.target.value)} />
        <Button className="submit-button" variant="contained" disabled={!email || error} onClick={handleNewsletter}>Submit</Button>
      </div>
      {showMessage && (
        <div className="location-alert">
          <AlertMessage
            message="Thank you for signing up!"
            show={showMessage}
            onClose={() => setShowMessage(false)}
            vertical="bottom"
            duration={3000}
          />
        </div>
      )}
    </>
  );
};

export default EmailInput;
