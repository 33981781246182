/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import {
  Button, Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../../../store';
import customFetch from '../../../common/util/customFetch';

const TermsAndConditionsModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const [checked, setChecked] = useState(false);
  const handleChange = () => setChecked((prevState) => !prevState);

  useEffect(() => {
    if (user && !user?.attributes?.termsAndConditions) {
      setOpen(true);
    }
  }, [user, navigate]);

  const onAccept = async () => {
    if (checked === true && user) {
      const updatedUser = {
        ...user,
        attributes: {
          ...user.attributes,
          termsAndConditions: checked,
        },
      };
      await customFetch(`/api/users/${user.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedUser),
      });
      dispatch(sessionActions.updateUser(updatedUser));
    }
    handleClose?.();
  };

  const modal = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown
      disableScrollLock
    >
      <div className="terms-condition-modal">
        <p className="terms-condition-modal__heading">We have updated our Terms and Conditions</p>
        <div className="terms-condition-modal__content-container">
          <div className="content">
            {/* yo here  */}
            <div className="date-section">
              <span>Last Updated</span>
              <p>March 26, 2024</p>
            </div>
            <p className="description">By accepting these website terms and conditions of use (“Terms”), You represent that You agree with these Terms. If You do not agree to accept these Terms You must not use this website.<br />These Terms will be applied fully to and affect your use of the Site and the Service. By using the Site or the Service, you agree to accept all terms and conditions written herein. You must not use the Site or the Service if you disagree with any of these Terms. We recommend that you print a copy of these Terms for your records. You may not access this Service if You are our direct competitor, except with our prior written consent. In addition, You may not access this Site for purposes of monitoring its availability, performance or functionality, or for any other benchmarking or competitive purposes.</p>
            <h2 className="heading">1. Definitions</h2>
            <p className="description"><span className="point">1.1 “Device”</span> means the hardware provided by PEEQ necessary to enable the Service which is intended to be physically included in any packages subject to the Service.</p>
            <p className="description"><span className="point">1.2. "Takeapeeq", "We", “Us”, or "Our"</span> means PEEQ LLC or its subsidiaries, including the affiliated company(s) providing services to You, as applicable.</p>
            <p className="description"><span className="point">1.3. "Service"</span> means item or package tracking functionality, and related tools, available on the Site; and any and all content, software, data, information and materials contained therein and transactions completed thereon.</p>
            <p className="description"><span className="point">1.4. “Site”</span> means the website accessible at <Link to="/takeapeeq.com">takeapeeq.com</Link>, and any other website or mobile application which links to these Terms.</p>
            <p className="description"><span className="point">1.5. "Third Party Content"</span> means certain third party analysis, content, tools, features, materials, websites, services, or advertisements which PEEQ makes available on or through this Service, or to which PEEQ links on the Site or any mobile application.</p>
            <p className="description"><span className="point">1.6. "You" or "Your"</span> means the natural person or legal entity which is agreeing to these Terms.</p>

            <h2 className="heading">2. Binding Agreement</h2>
            <p className="description"><span className="point">2.1</span> You must be 18 years of age or older to use the service. You represent and warrant that You have full power and authority to enter into this Agreement and that, in doing so, You will not violate any other agreement to which You are a party. Our Service request certain information about You. In order to use our Services, You may be asked to provide certain information about yourself (“User Data”). You agree to provide accurate, current and complete User Data. You acknowledge and agree that PEEQ will collect and store Your User Data and that it may be used and shared with law enforcement and others to prevent fraud and help investigate crimes. You agree to, and release PEEQ from any liability in connection with, these activities.</p>
            <p className="description"><span className="point">2.2</span> All other terms and conditions or related representations on this Site that govern the Services (the "Additional Terms") are incorporated into the Terms by this reference. The Terms and any Additional Terms (collectively, the "Agreement") sets forth the terms and conditions which govern Your access to and use of the Services. The Agreement sets forth the entire, final and exclusive agreement between PEEQ and You with respect to Your use of, and access to, the Service, and supersedes all previous oral and written terms, representations, or understandings concerning Your use of, and access to, the Service. This Agreement is effective between You and PEEQ as of the date of Your acceptance of this Agreement.</p>
            <p className="description"><span className="point">2.3</span> PEEQ shall not provide any services or Devices, or provide any benefit to the extent that the provision of such services or benefit would violate applicable law or expose PEEQ or its affiliates to any sanction, prohibition, or restriction under UN Security Council Resolutions, or under other criminal, trade, or economic sanctions, laws, or regulations. Should PEEQ discover any activity which could be considered in violation of such sanction, prohibition, or restriction, PEEQ reserves the right to immediately terminate any account engaged in such activity, as well as report such individuals and activity to the applicable law enforcement authorities for prosecution to the fullest extent of the law.</p>
            <p className="description"><span className="point">2.4</span> The information provided when using the Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to use the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. <br /> The Service is not tailored to comply with industry-specific regulations (for example, the US Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if Your interactions would be subjected to such laws, You may not use the Service.</p>
            <p className="description"><span className="point">2.5</span> By using the Service, You agree to transact electronically through the Site. You agree that Your electronic signature is the legal equivalent of Your manual signature. You further agree that Your use of a key pad, mouse or other device to select an item, button, icon or similar act/action, constitutes Your signature as if actually signed by You in writing. You also agree that no certification authority or other third party verification is necessary to validate Your electronic signature, and the lack of such certification or third party verification will not in any way affect the enforceability of Your electronic signature.</p>
            <p className="description"><span className="point">2.6</span> By providing Your telephone number, You are providing express written consent to receive communications from PEEQ, (including its agents, service providers, and affiliates) for any purpose, including but not limited to marketing various services from both PEEQ and companies PEEQ has joint marketing agreements with. Additionally, You agree to receive communications from PEEQ regarding Your service, and any information You may have obtained via Your use of the Site. You agree that these communications may include, but are not limited to, the use of an Automated Telephone Dialing System, prerecorded and/or artificial voice, SMS, MMS, text, fax, email or other similar means regardless of whether Your phone number is registered on a state or federal Do Not Call list. You agree that PEEQ is not responsible for any charges to You regarding these communications. Standard voice and data rates may apply. Further, You understand that You do not need to provide this consent to call as a condition to receive any good or service, in which case You will not provide Your phone number.</p>
            <h2 className="heading">3. Our Proprietary Rights</h2>
            <p className="description"><span className="point">3.1</span> As between PEEQ and You, and subject to Your right, title and interest in data You submit through this Service or otherwise provide or submit to PEEQ, PEEQ owns all right, title and interest in and to the Service, including all related intellectual property rights subsisting therein. Subject to the limited rights expressly granted hereunder, PEEQ reserves all rights, title and interest in and to the Service, including all related intellectual property rights subsisting therein. We grant no rights to You hereunder other than as expressly set forth herein. This Service and all materials and information published thereon are protected by U.S. and foreign copyright and other intellectual property laws. You agree to protect the proprietary rights of PEEQ and to comply with all reasonable written requests made by PEEQ or its third party licensors to protect their and others' rights in the Site and materials and content made available on or through the Service.</p>
            <p className="description"><span className="point">3.2</span> PEEQ, <Link to="/takeapeeq.com">takeapeeq.com</Link> and PEEQ LLC, and our other trademarks and service marks are the property of PEEQ, or PEEQ’s affiliates. PEEQ's trademarks and trade dress may not be used in any form without the prior written consent of PEEQ, and any use shall be subject to PEEQ's then-current policies and requirements. All other trademarks, services marks, logos, designs and trade dress not owned by PEEQ or its affiliates that appear on this Service are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by PEEQ.</p>

            <h2 className="heading">4. Description of Service and License</h2>
            <p className="description"><span className="point">4.1</span> PEEQ is providing You with a platform by which You can interact with PEEQ to determine the location of items which are in close proximity to a Device, and where You obtain Services from PEEQ. Your relationship with PEEQ is limited to the use of the Device and the Services offered through the Site.</p>
            <p className="description"><span className="point">4.2</span> Subject to this Agreement, PEEQ hereby grants You a non-exclusive, non-transferable revocable license for Your purposes: (i) to access and use the Service; (ii) to access and use reports, materials or other content generated through or available on the Site; and (iii) manage Your account, materials, or other content generated through or available on the Site. All rights not explicitly granted in this Agreement are reserved by PEEQ. You may not use the Service or the Site in any manner inconsistent with this Agreement.</p>
            <p className="description"><span className="point">4.3</span> You shall not remove any copyright, patent, trademark or other proprietary or restrictive notice or legend contained on of the Sites, and You shall reproduce all such notices and legends on all copies of documents, reports and other materials downloaded, printed or distributed from the Service which copies are permitted to be made hereunder.</p>
            <p className="description"><span className="point">4.4</span> When You provide us content using Our Services (directly or indirectly), You grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to reproduce, adapt, publish, create derivative works from, copy, display, upload, publicly perform, distribute, store, modify and otherwise use Your content, without compensation to you, in connection with the operation of the Site or the promotion, advertising or marketing of the Services, in any form, medium or technology now known or later developed, including, but not limited to, social media accounts, emails, Our customer list, advertisements, and other promotional materials. Unless you request otherwise, we may also share your content with our retail partners.</p>
            <p className="description"><span className="point">4.5</span> You agree that Your purchase of a Device does not include any ongoing obligations for maintenance, updates, or upgrades by PEEQ. You further agree that your use of the Service does not include any ongoing obligations for upgrades, patches, or “bug fixes”. Your USE OF THE SERVICE AND ANY purchase of a Device is “as is” and no warranties of any sort; including any warranty of merchantability, non-infringement, or fitness for a particular purpose are included in your purchase of such Device OR USE OF THE SERVICE.</p>
            <p className="description"><span className="point">4.6</span> You represent and warrant that, for all such content You provide to PEEQ, You own or otherwise control all necessary rights to do so and to meet Your obligations under this Agreement. You represent and warrant that such content is accurate. You represent and warrant that use of any such content (including derivative works) by Us, our users, or others in contract with Us, and in compliance with this Agreement, does not and will not infringe any intellectual property rights of any third party. PEEQ and its affiliates take no responsibility and assume no liability for any content provided by You or any third party.</p>
            <p className="description"><span className="point">4.7</span> We reserve all rights not expressly granted to You in and to the Service and Device.</p>

            <h2 className="heading">5. Terms Applicable to Purchases on the Site</h2>
            <p className="description"><span className="point">5.1</span> Purchase Terms Generally:</p>
            <p className="description">
              Prior to the purchase of any Devices on Our Site, unless You pay through an alternative payment method that we accept on the Site, You must provide a valid credit card number and associated payment information, including all of the following: (i) Your name as it appears on the credit card; (ii) Your credit card number; (iii) the credit card type; (iv) the date of expiration; and (v) any activation numbers or codes needed to charge Your credit card. By submitting that information to us, You hereby authorize us to charge Your card at Our convenience but within thirty (30) days of credit card authorization.
            </p>
            <p className="description"><span className="point">5.2</span> Return Policy and Shipping and Delivery Policy:</p>
            <p className="description">
              (a) If You are not fully satisfied with a purchase from the Site, You may return it in the item&apos;s original packaging within thirty (30) days of the purchase date. Additional restrictions may apply. You are responsible for and agree to pay for all shipping costs displayed on Your invoice as well as any customs and/or duties on international orders.
            </p>
            <p className="description">
              (b) Unless otherwise prohibited by law, this remedy will constitute Your sole remedy and entire recourse against Us for a Device that is damaged, stolen, or lost and is in lieu of any other remedy otherwise available as a matter of law or equity.
            </p>
            <p className="description">
              (c) Any claim by You for a damaged, stolen, or lost Device must be made to Us in writing within sixty (60) days of the day that You became aware or should have reasonably become aware of the alleged damage, theft, or loss.
            </p>
            <p className="description"><span className="point">5.3</span> Methods of Payment, Credit Card Terms and Taxes:</p>
            <p className="description">(a) You agree to pay the price applicable (including any sales taxes, surcharges and any delivery fees) as of the time You submitted the order. We or Our payment processer will automatically bill Your credit card or other form of payment submitted as part of the order process for such price.</p>
            <p className="description">(b) You agree that You will not use any credit card or other form of payment unless You have all necessary legal authorization to do so. You agree to pay all fees and charges incurred in connection with Your purchases (including any applicable taxes) at the rates in effect when the charges were incurred. Unless You notify Us of any discrepancies within thirty (30) days after they first appear on Your credit card statement, You agree that they will be deemed accepted by You for all purposes. If We do not receive payment from Your credit card issuer or its agent, You agree to pay all amounts due upon demand by Us or Our agents. Sales taxes, or other taxes, customs, import/export charges, or similar governmental charges are not included in the price of the products. You are responsible for paying any such taxes or charges imposed on Your purchases, including sales, use or value-added taxes. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.</p>
            <p className="description"><span className="point">5.4</span> Order Acceptance Policy:</p>
            <p className="description">Your receipt of an electronic or other form of order confirmation does not signify our acceptance of Your order, nor does it constitute confirmation of an offer from us. PEEQ reserves the right at any time after receipt of Your order to accept or decline Your order for any reason. PEEQ further reserves the right any time after receipt of Your order, without prior notice to You, to supply less than the quantity You ordered of any item. Your order will be deemed accepted by PEEQ upon our delivery of products or services that You have ordered. We may require additional verifications or information before accepting any order. Not with standing the foregoing, You agree that, if we cancel all or a portion of Your order or if we provide You less than the quantity You ordered, Your sole and exclusive remedy is either that: (a) we will issue a credit to Your credit card account in the amount charged for the cancelled portion or the quantity not provided (if Your credit card has already been charged for the order); or (b) we will not charge Your credit card for the cancelled portion of the order or the quantity not provided. Do not assume that a cancellation or change of an order You have placed with PEEQ has been effected until You receive a confirmation from PEEQ via email or the Site. As stated above, You will be responsible for, and Your credit card or third-party payment account may be charged for, the payment of all fees associated with orders already processed or shipped before Your cancellation/change request or a request to terminate Your credit card account was received.</p>
            <p className="description"><span className="point">5.5</span> No Responsibility to Sell Mispriced Products or Services:</p>
            <p className="description">We do our best to describe every item, product or service offered on the Site as accurately as possible. However, we do not warrant that specifications or pricing on the Site is complete, accurate, reliable, current, or error-free, and Your electronic display may not accurately reflect the actual colors or details of the products. All products are subject to availability, and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason. In the event of any errors relating to the pricing or specifications of any item, product or service, PEEQ shall have the right to refuse or cancel any orders in its sole discretion.<br /><br />If we charged Your credit card prior to cancellation, we will issue a credit to Your account in the amount of the charge. Additional terms may apply and will be specified on the Site when they do apply. If a product You purchased from us is not as described, Your sole remedy is to return it in unused condition, complete and undamaged, in the original packaging.</p>
            <p className="description"><span className="point">5.6</span> Modifications to Prices or Billing Terms:</p>
            <p className="description">The purchase of products and services on the Site is subject to availability. PRODUCTS DISPLAYED ON THE SITE MAY NOT BE AVAILABLE AT ALL TIMES AND MAY BE SUBSTITUTED OR DISCONTINUED AT ANY TIME. PEEQ RESERVES THE RIGHT, AT ANY TIME, TO CHANGE ITS PRICES AND BILLING METHODS FOR PRODUCTS SOLD, EFFECTIVE IMMEDIATELY UPON POSTING ON THE SITE OR BY EMAIL DELIVERY TO YOU.</p>
            <h2 className="heading">6. Restrictions on Use</h2>
            <p className="description"><span className="point">6.1</span> Except as otherwise expressly provided in these Terms or on the Site, You may not download, modify, copy, reproduce, republish, post, resell, upload, transmit or distribute any materials or content, including without limitation any report generated through the Site, except with the express written consent of PEEQ or its third party licensors.</p>
            <p className="description"><span className="point">6.2</span> In addition, You agree not to:</p>
            <p className="description">(a) Use the Device, Service, or Site for any purpose that is unlawful or prohibited by the Agreement or display, transmit or otherwise make available on or through the Service material that is infringing, threatening, harassing, libelous, hateful, racially or ethnically objectionable, unlawful, tortious, harmful to children, invasive of another's privacy or violative of third party privacy rights;</p>
            <p className="description">(b) Reverse engineer, decompile, modify, or create derivative works from any software or materials accessible by or on the Service or the Site;</p>
            <p className="description">(c) Frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of PEEQ without express written consent;</p>
            <p className="description">(d) Make any commercial or non-fair use of the copyrights, trademarks, logos, symbols, or any other mark, device, or commercial identifier of PEEQ or any of our third party licensors without the express written consent of PEEQ;</p>
            <p className="description">(e) Use the Device or access the Site in a manner that could damage, disable, overburden, or impair any PEEQ server or the networks connected to any PEEQ server;</p>
            <p className="description">(f) Interfere with any third party's use and enjoyment of the Service;</p>
            <p className="description">(g) Attempt to gain unauthorized access to the Service, accounts, computer systems, or networks connected to any PEEQ server through hacking, password mining, or any other means;</p>
            <p className="description">(h) Sublicense any license granted in or to materials on the Service (whether or not any of such acts are for commercial gain or advantage);</p>
            <p className="description">(i) Access the Service in order to build a competitive product or service, or copy any features, functions or graphics of the Services; or</p>
            <p className="description">(j) Use the platform or Services in any way which could be considered to conceal the origin and ownership of goods or money which have been obtained via criminal activity.</p>
            <p className="description">(k) Defraud or mislead us or other users, especially in any attempt to learn sensitive account information such as user passwords;</p>
            <p className="description">(l) Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Service;</p>
            <p className="description">(m) Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous use of repetitive text), that interferes with any party&apos;s uninterrupted use and enjoyment of the Service or modifies, impairs, disrupts, or alters with the use, features, functions, operation or maintenance of the Service;</p>
            <p className="description">(n) Attempt to impersonate another user or person or use the username of another user;</p>
            <p className="description">(o) Upload or transmit (or attempt to upload or transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation: “gifs”, pixels, web bugs, cookies, or other similar devices, sometimes referred to as “spyware” or “passive collection mechanisms”;</p>
            <p className="description">(p) Copy or adapt the Service&apos;s software, including but not limited to Flash, PHP, HTML, Javascript, or other code;</p>
            <p className="description">(q) Except as may be the result of standard search engine or Internet browser usage, to use, launch, develop or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, offline reader that accesses the Service, or use or launch any unauthorized script or other software; or</p>
            <p className="description">(r) Sell or otherwise transfer Your profile and username.</p>
            <h2 className="heading">7. Your Responsibilities</h2>
            <p className="description"><span className="point">7.1</span> You shall: (i) have sole responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of all content and data submitted to or published via the Service by You; (ii) comply with all applicable laws (including but not limited to export laws) in using the Device and Service; and (iii) use the Device and Service solely in accordance with any online guides or instructions made available on or through the Site. You shall not disclose or share any ID(s) and password(s) used to access this Service. You are responsible for all activity that occurs under Your ID(s) and password(s). You agree to notify PEEQ in writing promptly upon becoming aware of any unauthorized access or use of the Site by any party.</p>
            <p className="description"><span className="point">7.2</span> You agree that You are solely responsible for any and all tax, duties, levies, or similar obligations imposed by any state or federal taxing authority.</p>
            <h2 className="heading">8. Links And Third Party Information</h2>
            <p className="description"><span className="point">8.1</span> This Site may include Third Party Content. Because Third Party Content is not maintained or controlled by PEEQ, and as a matter of policy, PEEQ does not independently verify, prescreen or monitor any such Third Party Content. While we believe the Third Party Content is from reliable third party sources, we are not responsible for the availability, content, completeness, adequacy, utility or accuracy of such Third Party Content. PEEQ does not make any endorsement, express or implied, of any Third Party Content.</p>
            <p className="description"><span className="point">8.2</span> Certain Third Party Content is subject to additional specific terms and conditions which can be found in Additional Terms and in applicable areas of the Site and websites linked to this Site. You acknowledge and agree that Your use of such Third Party Content is subject to all these terms.</p>
            <p className="description"><span className="point">8.3</span> Service features that interoperate with Third Party Content depend on the continuing availability of such third party services and materials for use with the Service. If the third party providers of such services or materials cease to make the services or materials available on reasonable terms for the Services, we may cease providing such Service features.</p>
            <h2 className="heading">9. Disclaimers and Limits of Liability</h2>
            <p className="description"><span className="point">9.1</span> PEEQ is not responsible for accuracy of any Third Party Content containing information or other materials concerning the financial condition or solvency of any insurers or reinsurers, or application of policy wordings of any insurers or reinsurers.</p>
            <p className="description"><span className="point">9.2</span> THE SERVICE, DEVICE, AND THE INFORMATION AND DATA ON THE SITE ARE PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.</p>
            <p className="description"><span className="point">9.3</span> PEEQ SHALL NOT BE LIABLE FOR ANY SPECIAL, PUNITIVE, EXEMPLARY, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO LOST REVENUES OR LOST PROFITS, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSSES AND BASED ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE DEVICE, SITE, SERVICES, OR ANY INFORMATION OR SERVICE ADVERTISED OR OFFERED ON A LINKED WEBSITE. Some jurisdictions do not allow the limitation or exclusion of certain implied warranties, liability, incidental or consequential damages, so certain provisions of this Agreement may not apply to You.</p>
            <h2 className="heading">10. Indemnification</h2>
            <p className="description"><span className="point">10.1</span> We shall defend You against any claim, demand, suit, or proceeding ("Claim") made or brought against You by a third party alleging that the use of the Services as permitted hereunder infringes or misappropriates the intellectual property rights of a third party, and shall indemnify You for any damages finally awarded against, and for reasonable attorney's fees incurred by, You in connection with any such Claim; provided, that You (a) promptly give us written notice of the Claim; (b) give us sole control of the defense and settlement of the Claim (provided that We may not settle any Claim unless the settlement unconditionally releases You of all liability); and (c) provide to us all reasonable assistance, at Our expense</p>
            <p className="description"><span className="point">10.2</span> You shall defend us against any claim made or brought against us by a third party alleging that Your use of the Device or Services is in violation of this Agreement, or the data or information You submit through the Service, infringes or misappropriates the intellectual property rights of a third party, or violates applicable law; and You shall indemnify us for any damages finally awarded against, and for reasonable attorney's fees incurred by, us in connection with any such Claim; provided, that we: (a) promptly give You written notice of the Claim; (b) give You sole control of the defense and settlement of the Claim (provided that You may not settle any Claim unless the settlement unconditionally release us of all liability); and (c) provide to You all reasonable assistance, at our expense.</p>
            <p className="description"><span className="point">10.3</span> This Section 9 (Mutual Indemnification) states the indemnifying party's sole liability to, and the indemnified party's exclusive remedy against, the other party for any type of Claim described in this Section.</p>
            <h2 className="heading">11. Changes to Agreement</h2>
            <p className="description">PEEQ reserves the right to make changes, modifications, amendments, and/or updates to this Service and the Agreement. When these changes are made, we will make a new copy of the Agreement available on this Site. Changes to the Agreement shall be effective when posted. You understand and agree that continued use of the Site after the Agreement has changed will be treated as Your acceptance of the updated Agreement.</p>

            <h2 className="heading">12. Services Management and User Data.</h2>
            <p className="description">
              We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, notice or liability, to remove from the Service or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (4) otherwise manage the Service in a manner designed to protect our rights and to facilitate the proper functioning of the Service.
              <br />
              <br />
              We cannot guarantee the Service will be available at all times. Nothing in these Terms will be construed to obligate us to maintain or support the Service, these Terms, or to supply any corrections, updates or releases to the Service or these Terms.
              <br />
              <br />
              You are solely responsible for all data that you transmit or that relates to any activity related to the Service. We have no liability to you for the loss or corruption of any such data.
            </p>

            <h2 className="heading">13. Term and Termination.</h2>
            <p className="description">
              This Agreement commences on the date You accept it and continues until terminated in
              accordance with this paragraph. You may terminate Your use of the Service without cause at any
              time upon written notice to us. We may terminate access to and use of the Service (a) at any time
              with or without cause, upon notice to You or (b) upon termination of Your relationship with
              PEEQ for any reason. Sections 3 (Proprietary Rights), 8 (Links and Third Party Content), 9
              (Disclaimers and Limits of Liability), 10 (Indemnification), 15 (Governing Law), 16 (Injunctive
              Relief & Arbitration), 17 (Limitation on Time to File Claims), and 18 (Miscellaneous) shall
              survive any termination or expiration of this Agreement. We shall have no obligation to maintain
              or provide You a copy of any of Your data in the Service following termination or expiration of
              this Agreement, unless otherwise specified in other applicable agreement between You and
              PEEQ, or unless legally required to be retained. UPON TERMINATION YOUR ACCESS TO
              OR USE OF MATERIALS OR DATA STORED IN THE SERVICE WILL NOT BE
              ACCESSIBLE THROUGH THE SERVICE OR SITE. If we terminate or suspend your account
              for any reason, you are prohibited from registering and creating a new account under your name,
              a fake or borrowed name, or the name of any third party.
            </p>

            <h2 className="heading">14. Sanctions and Exports Controls</h2>
            <p className="description">Your purchase of a Device and these Terms are expressly made subject to any laws, regulations, orders or other restrictions which may be imposed by the Government of the United States of America on the transaction of business activities with certain countries or nationals or residents of certain countries. Devices and Services are not available through PEEQ to any Restricted Entity (as defined below). You represent and warrant that neither You nor Your organization is a Restricted Entity nor are You or Your organization using the Services on behalf of or for the benefit of a Restricted Entity. Restricted Entity shall mean any individual or organization owned or controlled by, or acting as an agent for, any person or entity with whom a U.S. citizen, national, or company organized under the laws of or operating in the U.S. is prohibited from engaging in transactions by U.S. laws, including without limitation, a person on the Specially Designated Nationals List published by the U.S. Department of the Treasury's Office of Foreign Assets Control.</p>
            <h2 className="heading">15. Governing Law</h2>
            <p className="description">This Agreement shall be governed by the laws of the Commonwealth of Massachusetts, without reference to the principles of conflicts of laws thereof. You hereby irrevocably and unconditionally accept and agree to submit to the exclusive jurisdiction of any state or federal court in the Commonwealth of Massachusetts, County of Suffolk to hear any dispute relating to this Agreement. You agree that the Uniform Computer Information Transactions Act (UCITA), as adopted by any state, is specifically excluded from application to this Agreement.</p>
            <h2 className="heading">16. Injunctive Relief & Arbitration.</h2>
            <p className="description">You acknowledge and agree that any violation of the Agreement relating to the disclosure, use, copying, distribution, display or publishing of the content made available through the Service by us or by third party licensors, including any software licensed hereunder, may result in irreparable injury and damage to PEEQ or its licensors that may not be adequately compensable in money damages, and for which PEEQ will have no adequate remedy at law. You, therefore, consent and agree that PEEQ may obtain injunctions, orders, or decrees as may be reasonably necessary to ensure compliance with this Agreement. You hereby waive any requirement of the posting of a bond that may apply for issuance of any injunctions, orders, or decrees.</p>
            <p className="point font-18 mb-30">PLEASE READ THIS SECTION CAREFULLY - IT AFFECTS YOUR LEGAL RIGHTS AND GOVERNS HOW YOU AND PEEQ CAN BRING CLAIMS AGAINST EACH OTHER. THIS SECTION WILL, WITH LIMITED EXCEPTION, REQUIRE YOU AND PEEQ TO SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS. THIS MEANS THAT YOU WILL NOT BE ABLE TO BRING A CLASS, COLLECTIVE, OR REPRESENTATIVE LAWSUIT IN A COURT OF LAW BEFORE A JUDGE OR JURY CONCERNING ANY DISPUTE THAT MAY ARISE WHICH IS COVERED BY THE ARBITRATION AGREEMENT AND ARE INSTEAD AGREEING TO SUBMIT ANY SUCH DISPUTE SOLELY ON YOUR OWN BEHALF TO AN IMPARTIAL ARBITRATOR.</p>
            <p className="description"><span className="point">16.1</span> Agreement to Arbitrate You and PEEQ mutually agree to forego the delay and expense of using a court of law and choose instead to benefit from the speedy, economical, and impartial dispute resolution procedure of using binding arbitration for any “Covered Claims” (as defined below) that arise between You and PEEQ, its related and affiliated companies, and/or any current or former employee, officer, or director of PEEQ or any related or affiliated company. You and PEEQ agree that this Arbitration and Class Action Waiver is governed by the Federal Arbitration Act, 9 U.S.C. § 1 et seq., and will survive even after the Agreement and any Services terminate. Any revision to or termination of the Agreement that modify or terminate this Arbitration and Class Action Waiver shall not apply to a pending arbitration, to any claim that accrued prior to the modification or termination, or to any claim that the asserting party knew about prior to the modification or termination, except as may be required by applicable law.</p>
            <p className="description"><span className="point">16.2</span> Claims Subject to Arbitration. Other than the exceptions in the “Excluded Claims” Section below, the “Covered Claims” include any and all controversies, disputes, disagreements, and claims arising out of, or relating to, this Agreement (including its enforcement, breach, performance, interpretation, validity, or termination), or Your access to and/or use of the Services, or the provision of content, services, and/or technology on or through the Site to the fullest extent allowed by law.</p>
            <p className="description"><span className="point">16.3</span> Excluded Claims, Jurisdiction, and Venue. The Covered Claims do not include (and thus shall not require arbitration of) the following types of claims that will hereafter be referred to as “Excluded Claims”: (a) any claims that cannot be required to be arbitrated as a matter of law; and (b) applications for provisional remedies, preliminary injunctions, and temporary restraining orders, including but not limited to those relating to actual or threatened infringement, misappropriation, or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights. Any Excluded Claims arising out of, or related to, this Agreement shall be instituted exclusively in the in the state courts located in the Commonwealth of Massachusetts, County of Suffolk or federal courts located in the Commonwealth of Massachusetts, which shall be the exclusive forum for such claims. You waive any and all objections to the exercise of jurisdiction over You by such courts and to venue in such courts.<br /><br />In the event You or PEEQ choose to pursue arbitration of both Covered Claims and Excluded Claims and the other party objects, the Covered Claims must be arbitrated. In no event will Covered Claims be joined with Excluded Claims and litigated in court unless both parties agree to waive arbitration</p>
            <p className="description"><span className="point">16.4</span> Class Action Waiver. Except as otherwise required under applicable law, You and PEEQ agree to arbitrate any Covered Claims only on an individual basis and hereby waive any right to bring, participate in, or receive money or any other relief from any representative, class, or collective proceeding (“Class Action Waiver”). No party may bring a claim on behalf of other individuals, and no arbitrator hearing any claim under this Agreement may: (a) without the consent of all parties, combine more than one individual’s claim or claims into a single case; (b) order, require, participate in, or facilitate production of class-wide contact information or notification of others of potential claims; or (c) arbitrate any form of a class, collective, or representative proceeding.</p>
            <p className="description"><span className="point">16.5 </span> Arbitrability Determinations. If a party violates the agreement to arbitrate by commencing an action asserting a Covered Claim in a court of law, then the court (and not an arbitrator) shall have the authority to resolve any disputes about the interpretation, formation, existence, enforceability, validity, and scope of this Arbitration and Class Action Waiver. If a party complies with the agreement to arbitrate and files for arbitration without filing a complaint in a court of law, then the arbitrator shall have the authority to resolve any disputes about the interpretation of this Arbitration and Class Action Waiver for purposes of discovery or the merits of the underlying claim, but shall have no authority to resolve any disputes about the formation, existence, enforceability, or validity of this Arbitration and Class Action Waiver, including the Class Action Waiver.</p>
            <p className="description"><span className="point">16.6 </span> Arbitration Rules, Procedures, and Costs. To initiate arbitration, the party desiring to pursue a legal dispute must prepare a written demand setting forth the claim(s) and deliver the written demand within the applicable statute of limitations period by email at support@takeapeeq.com. You and PEEQ agree that the arbitration shall be administered by the American Arbitration Association (“AAA”) before a single arbitrator mutually agreed upon by the parties, and if the parties cannot agree within thirty (30) days after names of potential arbitrators have been proposed, then by a single arbitrator who is chosen by AAA. Except to the extent that they are modified by the rules below, the AAA Commercial Arbitration Rules that are in effect at the time of the filing of the demand.</p>
            <p className="description"><span className="point">16.7 </span>The parties agree that the applicable AAA rules are modified as follows:</p>
            <p className="description">(a) Any arbitrator must be neutral as to all parties. Standards for the recusal of an arbitrator shall be the same standards under which trial judges are recused under Massachusetts law.</p>
            <p className="description">(b) No party is entitled to its attorneys’ fees, except as may be awarded in a matter authorized by and consistent with applicable law.</p>
            <p className="description">(c) All discovery shall be subject to any and all objections available under FRCP 26(b). Each party shall avoid broad or widespread collection, search, and production of documents, including electronically stored information. If a compelling need is demonstrated by the requesting party, the production shall: <br />(i) be narrowly tailored in scope; (ii) only come from sources that are reasonably accessible without undue burden or cost; and (iii) be produced in a searchable format, if possible without undue burden or cost, and which is usable by the receiving party and convenient and economical for the producing party. Where the costs and burdens of the requested discovery outweigh its likely benefit, considering the needs of the case, the amount in controversy, and the importance of the discovery in resolving the issues, the arbitrator shall deny such requests or order production on condition that the requesting party advance to the producing party the reasonable costs involved in making the production, subject to the allocation of costs in the final award.</p>
            <p className="description">(d) The arbitrator shall have the authority to award the same damages and other relief that would have been available in court pursuant to the law governing the Covered Claim(s).</p>
            <p className="description">(e) Either party shall have the right to file motions to dismiss and motions for summary judgment/adjudication.</p>
            <p className="description">(f) The arbitrator shall have the authority to issue an award or partial award without conducting a hearing on the grounds that there is no claim on which relief can be granted or that there is no genuine issue of material fact to resolve at a hearing.</p>
            <p className="description">(g) The Federal Rules of Evidence shall apply to all arbitration proceedings.</p>
            <p className="description">(h) For discovery purposes only, an arbitrator may consolidate claims filed by multiple individuals, each on their own behalf, in a single arbitration proceeding, or may conduct a joint hearing for efficiency purposes, so long as the arbitrator does not (a) certify (conditionally or otherwise) a collective, class, or representative action that includes individuals who have not themselves already submitted their own individual claims, or (b) authorize the issuance of notice of the arbitration to individuals on the grounds that the arbitrator or any party believes there are other individuals who are similarly situated to or share commonality with a party to the arbitration.</p>
            <p className="description">(i) The arbitrator must issue a decision in writing, setting forth in summary form the reasons for the arbitrator’s determination and the legal basis therefor.</p>
            <p className="description">(j) The arbitrator’s authority shall be limited to deciding the case submitted by the parties to the arbitration. Therefore, no decision by any arbitrator shall serve as precedent in other arbitrations except to preclude the same claim from being re-arbitrated between the same parties.</p>
            <p className="description">(k) The parties may settle any dispute on a mutual basis without involvement of the arbitrator.</p>
            <p className="description">(l) If You initiate arbitration as an individual and not in your capacity as an employee or agent of a business, You will pay the first $250, and PEEQ will pay all other filing, administrative, or hearing fees. If the PEEQ initiates arbitration, PEEQ will pay all filing, administrative, and hearing fees. Regardless of which party initiates arbitration, You will remain responsible for Your attorneys’ fees and costs unless the law governing the Covered Claim provides for an award of attorneys’ fees and costs and the arbitrator determines as part of the arbitration award that You may recover a certain amount of attorneys’ fees and costs.</p>
            <p className="description"><span className="point">16.8 </span> If any term or condition in this Arbitration and Class Action Waiver is determined to be unenforceable or in conflict with a mandatory provision of applicable law, it shall be construed to incorporate any mandatory provision, or the unenforceable or conflicting term or condition shall be automatically severed and the remainder of this Arbitration and Class Action Waiver shall not be affected. Provided, however, that if the Class Action Waiver is found to be unenforceable, then any claim brought on a class, collective, or representative action basis shall be adjudicated exclusively in the state and federal courts located in the Commonwealth of Massachusetts, which shall be the exclusive forum for such claims. You waive any and all objections to the exercise of jurisdiction over You by such courts and to venue in such courts.</p>
            <h2 className="heading">17. Limitation on Time to File Claims</h2>
            <p className="description">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SITE MUST BE COMMENCED WITHIN TWO (2) YEARS AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
            <h2 className="heading">18. Miscellaneous</h2>
            <p className="description"><span className="point">18.1</span> We may assign our rights and obligations under this Agreement, without notice, to: (a) any affiliate of PEEQ; or (b) any party or its affiliate acquiring all or substantially all of the assets or stock by merger or otherwise of PEEQ or any affiliate of PEEQ. This Agreement may not be assigned by You without our prior written consent. These Terms constitute the entire agreement and understanding between You and us. Our failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement shall be deemed invalid, void, or for any reason unenforceable, that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provisions.</p>
            <p className="description"><span className="point">18.2</span> You create or use an account on behalf of a business entity, You represent that You are authorized to act on behalf of such business and bind the business to this Agreement. Such account is owned and controlled by the business entity. No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.</p>
            <p className="description mb-0">Questions or comments regarding the Service or the Agreement should be directed to PEEQ at <a href="#" className="point">support@takeapeeq.com</a></p>
          </div>
          <p className="instruction">
            You will not be able to continue to use this website and its services without accepting our terms and
            conditions. If you want to stop using this website,
            {' '}
            <Link to="/contact-us" className="link">contact us</Link>
            {' '}
            to delete your account.
          </p>
        </div>
        <div className="divider" />
        <div className="terms-condition-modal__footer">
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checkedIcon={(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <rect width="16" height="16" rx="4" fill="#1A74E2" />
                      <path d="M6.57538 11C6.37528 11 6.18519 10.9219 6.04512 10.7851L3.2137 8.02128C2.92356 7.73805 2.92356 7.26927 3.2137 6.98605C3.50385 6.70283 3.98409 6.70283 4.27423 6.98605L6.57538 9.2323L11.718 4.21242C12.0081 3.92919 12.4883 3.92919 12.7785 4.21242C13.0686 4.49564 13.0686 4.96442 12.7785 5.24765L7.10565 10.7851C6.96558 10.9219 6.77548 11 6.57538 11Z" fill="white" />
                    </svg>
                  )}
                  icon={(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#A0B0D0" />
                    </svg>
                  )}
                  onChange={handleChange}
                  checked={checked}
                />
              )}
              label="I have read the terms and conditions"
            />
          </FormGroup>
          <Button disabled={!checked} onClick={onAccept}>I agree</Button>
        </div>
      </div>
    </Modal>
  );
  return modal;
};

export default TermsAndConditionsModal;
