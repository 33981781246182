import { useEffect } from 'react';

const GoogleTagManagerScript = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the attributes for the script tag
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11467790314';

    // Append the script element to the <head> element
    document.head.appendChild(script);

    // Function to load Google Tag Manager configuration
    window.dataLayer = window.dataLayer || [];
    function gtag(...args) { window.dataLayer.push(...args); }
    gtag('js', new Date());
    gtag('config', 'AW-11467790314');

    // Cleanup function to remove the script tag when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // Return null since this component doesn't render anything
  return null;
};

export default GoogleTagManagerScript;
