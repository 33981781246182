import React, {
  useEffect, useState, lazy,
} from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from './common/util/useQuery';
import { useEffectAsync } from './reactHelper';
import { devicesActions, sessionActions } from './store';
import { identifyToUserFlow, logoutUserFlow } from './user-flow';
import customFetch from './common/util/customFetch';
import FabllbackProvider from './FabllbackProvider';
import ScreenLoader from './common/components/ScreenLoader';

const MainPage = lazy(() => import('./main/MainPage'));
const RouteReportPage = lazy(() => import('./reports/RouteReportPage'));
const ServerPage = lazy(() => import('./settings/ServerPage'));
const UsersPage = lazy(() => import('./settings/UsersPage'));
const DevicePage = lazy(() => import('./settings/DevicePage'));
const UserPage = lazy(() => import('./settings/UserPage'));
const NotificationsPage = lazy(() => import('./settings/NotificationsPage'));
const NotificationPage = lazy(() => import('./settings/NotificationPage'));
const NewNotificationPage = lazy(() => import('./notifications/NotificationPage'));
const GroupsPage = lazy(() => import('./settings/GroupsPage'));
const GroupPage = lazy(() => import('./settings/GroupPage'));
const PositionPage = lazy(() => import('./other/PositionPage'));
const NetworkPage = lazy(() => import('./other/NetworkPage'));
const EventReportPage = lazy(() => import('./reports/EventReportPage'));
const ReplayPage = lazy(() => import('./other/ReplayPage'));
const TripReportPage = lazy(() => import('./reports/TripReportPage'));
const StopReportPage = lazy(() => import('./reports/StopReportPage'));
const SummaryReportPage = lazy(() => import('./reports/SummaryReportPage'));
const ChartReportPage = lazy(() => import('./reports/ChartReportPage'));
const DriversPage = lazy(() => import('./settings/DriversPage'));
const DriverPage = lazy(() => import('./settings/DriverPage'));
const CalendarsPage = lazy(() => import('./settings/CalendarsPage'));
const CalendarPage = lazy(() => import('./settings/CalendarPage'));
const ComputedAttributesPage = lazy(() => import('./settings/ComputedAttributesPage'));
const ComputedAttributePage = lazy(() => import('./settings/ComputedAttributePage'));
const MaintenancesPage = lazy(() => import('./settings/MaintenancesPage'));
const MaintenancePage = lazy(() => import('./settings/MaintenancePage'));
const CommandsPage = lazy(() => import('./settings/CommandsPage'));
const CommandPage = lazy(() => import('./settings/CommandPage'));
const StatisticsPage = lazy(() => import('./reports/StatisticsPage'));
const HowItWorksPage = lazy(() => import('./HowItWorksPage/HowItWorksPage'));
const AboutUsPage = lazy(() => import('./AboutUsPage/AboutUsPage'));
const LoginPage = lazy(() => import('./landingPage/LoginPage/LoginPage'));
const RegisterPage = lazy(() => import('./landingPage/RegisterPage/RegisterPage'));
const GeofencesPage = lazy(() => import('./other/Geofence/GeofencesPage'));
const GeofencePage = lazy(() => import('./settings/GeofencePage'));
const EventPage = lazy(() => import('./other/EventPage'));
const PreferencesPage = lazy(() => import('./settings/PreferencesPage'));
const AccumulatorsPage = lazy(() => import('./settings/AccumulatorsPage'));
const CommandSendPage = lazy(() => import('./settings/CommandSendPage'));
const App = lazy(() => import('./App'));
const DevicesPage = lazy(() => import('./settings/DevicesPage'));
const PageNotFound = lazy(() => import('./landingPage/PageNotFound/PageNotFound'));
const ContactUs = lazy(() => import('./ContactUs/ContactUs'));
const PasswordProtection = lazy(() => import('./PasswordProtection/PasswordProtection'));
const ShopPage = lazy(() => import('./ShopPage/ShopPage'));
const CheckoutPage = lazy(() => import('./CheckoutPage/CheckoutPage'));
const OrdersPage = lazy(() => import('./settings/OrdersPage'));
const NewOrdersPage = lazy(() => import('./OrdersPage/OrdersPage'));
const SubscriptionSummary = lazy(() => import('./SubscriptionSummary/SubscriptionSummary'));
const OrderPage = lazy(() => import('./settings/OrderPage'));
const PaymentStatusPage = lazy(() => import('./PaymentStatusPage/PaymentStatusPage'));
const OrderDetailPage = lazy(() => import('./settings/OrderDetailPage'));
const ResetPassword = lazy(() => import('./landingPage/ResetPassword/ResetPassword'));
const ConfirmEmail = lazy(() => import('./landingPage/ConfirmEmail/ConfirmEmail'));
const VerifiedEmail = lazy(() => import('./landingPage/VerifiedEmail/VerifiedEmail'));
const ResentPasswordLink = lazy(() => import('./landingPage/VerifiedEmail/ResentPasswordLink'));
const CodeVerificationPage = lazy(() => import('./landingPage/CodeVerificationPage/CodeVerificationPage'));
const TwilioCommandPage = lazy(() => import('./settings/TwilioCommandPage'));
const AlertsPage = lazy(() => import('./settings/AlertsPage/AlertsPage'));
const NewHomePage = lazy(() => import('./homePage/NewHomePage/NewHomePage'));
const SettingLayout = lazy(() => import('./settings/profile/SettingsLayout'));
const AccountSettings = lazy(() => import('./settings/profile/AccountSettings'));
const SettingsAddressFormModal = lazy(() => import('./settings/SettingsAddressFormModal'));
const PrivacyPolicy = lazy(() => import('./homePage/footer/pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./homePage/footer/pages/TermsAndConditions'));
const WarrentyPolicy = lazy(() => import('./homePage/footer/pages/WarrentyPolicy'));
const ShippingPolicy = lazy(() => import('./homePage/footer/pages/ShippingPolicy'));
const ReturnPolicy = lazy(() => import('./homePage/footer/pages/ReturnPolicy'));
const Newsletter = lazy(() => import('./homePage/footer/pages/Newsletter'));
const HelpCenter = lazy(() => import('./HelpCenter/HelpCenter'));
const HelpGetStarted = lazy(() => import('./HelpCenter/pages/getStarted/GetStarted'));
const HelpHardwareManual = lazy(() => import('./HelpCenter/pages/hardwareManual/HardwareMannaul'));
const HelpManageAccount = lazy(() => import('./HelpCenter/pages/manageYourAccount/ManageYourAccount'));
const HelpNewsUpdate = lazy(() => import('./HelpCenter/pages/newsUpdate/NewsUpdate'));
const HelpTroubleshoot = lazy(() => import('./HelpCenter/pages/troubleshoot/Troubleshoot'));
const HelpUseoYourTracker = lazy(() => import('./HelpCenter/pages/usingYourTracker/UsingYourTracker'));
const ThankYouPage = lazy(() => import('./ThankYouPage/ThankYouPage'));
const VerifiedPasswordReset = lazy(() => import('./landingPage/VerifiedEmail/VerifiedPasswordReset'));
const NewsletterUnsubscribeSuccess = lazy(() => import('./homePage/footer/pages/NewsletterUnsubscribeSuccess'));

const defaultFetch = window.fetch;

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.session.user);

  const path = location?.pathname;
  const platform = process.env.REACT_APP_PLATFORM;
  const disablePasswordProtection = process.env.REACT_APP_DISABLE_PASSWORD_PROTECTION;

  const passwordProtectionPage = path === '/password-protection';
  const passwordProtection = window.localStorage.getItem('passwordProtection');

  const [redirectsHandled, setRedirectsHandled] = useState(false);

  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    window.fetch = async (...args) => {
      const response = await defaultFetch(...args);
      if (
        // Session is no longer valid
        response.status === 401 &&
        // Session request is not a login request
        args?.[1]?.method !== 'POST' && !args[0].includes('/api/session')
      ) {
        window.location = '/login?error=session-expired';
      }
      // Don't mess with response much, things can break.
      return response;
    };

    return () => {
      window.fetch = defaultFetch;
    };
  }, []);

  useEffect(() => {
    if (user) {
      identifyToUserFlow(user);
    } else {
      logoutUserFlow();
    }
  }, [user]);

  useEffectAsync(async () => {
    if (!passwordProtection && !passwordProtectionPage && disablePasswordProtection === 'false') {
      navigate('/password-protection');
    } else if (platform !== 'customer' && (path !== '/login' && path !== '/reset-password' && path !== '/verification')) {
      let shouldRedirect = true;
      if (user) {
        shouldRedirect = false;
      } else {
        const response = await customFetch('/api/session');
        if (response.ok) {
          dispatch(sessionActions.updateUser(await response.json()));
          shouldRedirect = false;
        }
      }
      if (shouldRedirect) {
        navigate('/login');
      }
    }
  }, [user]);

  useEffectAsync(async () => {
    if (query.get('token')) {
      const token = query.get('token');
      await customFetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate(pathname);
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await customFetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.select(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/');
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return (<ScreenLoader />);
  }

  if (!passwordProtection && !passwordProtectionPage && disablePasswordProtection === 'false') return null;

  return (
    <FabllbackProvider>
      <Routes>
        <Route path="/password-protection" element={<PasswordProtection />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verified-password-reset" element={<VerifiedPasswordReset />} />
        <Route path="/verification" element={<CodeVerificationPage />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/reset-password-link" element={<ResentPasswordLink />} />
        <Route path="/email-verified" element={<VerifiedEmail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/works" element={<HowItWorksPage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/payment" element={<PaymentStatusPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/warranty" element={<WarrentyPolicy />} />
        <Route path="/shipping" element={<ShippingPolicy />} />
        <Route path="/return" element={<ReturnPolicy />} />
        {/* <Route path="/support" element={<Support />} /> */}
        {/* <Route path="/faqs" element={<FAQs />} /> */}
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/newsletter-unsubscribe" element={<NewsletterUnsubscribeSuccess />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/support">
          <Route index element={<HelpCenter />} />
          <Route path="get-started" element={<HelpGetStarted />} />
          <Route path="manage-account" element={<HelpManageAccount />} />
          <Route path="hardware-manual" element={<HelpHardwareManual />} />
          <Route path="news-update" element={<HelpNewsUpdate />} />
          <Route path="troubleshoot" element={<HelpTroubleshoot />} />
          <Route path="using-tracker" element={<HelpUseoYourTracker />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<App />}>
          {/* <Route index element={<HomePage />} /> */}
          <Route index element={<NewHomePage />} />
          <Route path="/devices" element={<MainPage />} />
          <Route path="position/:id" element={<PositionPage />} />
          <Route path="network/:positionId" element={<NetworkPage />} />
          <Route path="event/:id" element={<EventPage />} />
          <Route path="replay/:deviceId" element={<ReplayPage />} />
          <Route path="geofences" element={<GeofencesPage />} />
          <Route path="notifications" element={<NewNotificationPage />} />

          <Route path="settings">
            <Route path="accumulators/:deviceId" element={<AccumulatorsPage />} />
            <Route path="calendars" element={<CalendarsPage />} />
            <Route path="calendar/:id" element={<CalendarPage />} />
            <Route path="calendar" element={<CalendarPage />} />
            <Route path="commands" element={<CommandsPage />} />
            <Route path="command/:id" element={<CommandPage />} />
            <Route path="command" element={<CommandPage />} />
            <Route path="command-send/:deviceId" element={<CommandSendPage />} />
            <Route path="attributes" element={<ComputedAttributesPage />} />
            <Route path="attribute/:id" element={<ComputedAttributePage />} />
            <Route path="attribute" element={<ComputedAttributePage />} />
            <Route path="devices" element={<DevicesPage />} />
            <Route path="device/:id" element={<DevicePage />} />
            <Route path="device" element={<DevicePage />} />
            <Route path="drivers" element={<DriversPage />} />
            <Route path="driver/:id" element={<DriverPage />} />
            <Route path="driver" element={<DriverPage />} />
            <Route path="geofence/:id" element={<GeofencePage />} />
            <Route path="geofence" element={<GeofencePage />} />
            <Route path="groups" element={<GroupsPage />} />
            <Route path="group/:id" element={<GroupPage />} />
            <Route path="group" element={<GroupPage />} />
            <Route path="maintenances" element={<MaintenancesPage />} />
            <Route path="maintenance/:id" element={<MaintenancePage />} />
            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="notification/:id" element={<NotificationPage />} />
            <Route path="notification" element={<NotificationPage />} />
            <Route path="preferences" element={<PreferencesPage />} />
            <Route path="server" element={<ServerPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="user/:id" element={<UserPage />} />
            <Route path="user" element={<UserPage />} />
            <Route element={<SettingLayout />}>
              <Route path="profile" element={<AccountSettings />}>
                <Route path="address">
                  <Route index element={<SettingsAddressFormModal />} />
                  <Route path=":id" element={<SettingsAddressFormModal />} />
                </Route>
              </Route>
              <Route path="alerts" element={<AlertsPage />} />
              <Route path="subscriptions" element={<SubscriptionSummary />} />
              <Route path="orders" element={<NewOrdersPage />} />
            </Route>
            <Route path="all-orders" element={<OrdersPage />} />
            <Route path="order/:id" element={<OrderPage />} />
            <Route path="order-detail/:id" element={<OrderDetailPage />} />
            <Route path="twilio-command" element={<TwilioCommandPage />} />

          </Route>

          <Route path="reports">
            <Route path="chart" element={<ChartReportPage />} />
            <Route path="event" element={<EventReportPage />} />
            <Route path="route" element={<RouteReportPage />} />
            <Route path="statistics" element={<StatisticsPage />} />
            <Route path="stop" element={<StopReportPage />} />
            <Route path="summary" element={<SummaryReportPage />} />
            <Route path="trip" element={<TripReportPage />} />
          </Route>
        </Route>
      </Routes>
    </FabllbackProvider>
  );
};

export default Navigation;
