import React from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions, sessionActions } from '../store';
import SubscriptionItemsDropdown from '../main/SubscriptionItemsDropdown';
import { useEffectAsync } from '../reactHelper';
import customFetch from '../common/util/customFetch';
import { formatPrice } from '../common/util/formatter';

const OrderSubscriptionLineItem = () => {
  const dispatch = useDispatch();
  const orderSubscription = useSelector((state) => state.cart.order.orderSubscription);
  const subscriptionItems = useSelector((state) => state.session.subscriptionItemsNonRecursive);

  useEffectAsync(async () => {
    if (subscriptionItems.length === 0) {
      const response = await customFetch('/api/item/subscription');
      if (response.ok) {
        let items = await response.json();
        items = items.filter((item) => item.isRecursive);
        dispatch(sessionActions.updateSubscriptionItemsNonRecursive(items));
      }
    }
  }, [dispatch, subscriptionItems]);

  const handleResetOrderSubscription = () => {
    dispatch(cartActions.setOrderSubscriptionDeclined());
    dispatch(cartActions.resetOrderCouponCodeDetails());
  };

  return (
    <div className="order-form subscription-form">
      <div className="order-section-one">
        <div className="product-details">
          <div className="img-section">
            <img className="img-fluid" alt="product-img" src={`${process.env.REACT_APP_BUCKET_URL}/${subscriptionItems[0]?.imageName}`} />
          </div>
          <div>
            <div className="product-name">
              <span>Subscription Plan</span>
              <p className="sub-text">Each POM requires its own plan and will auto-renew based on your subscription plan.</p>
            </div>
            <SubscriptionItemsDropdown subscriptionItems={subscriptionItems} />
          </div>
        </div>
        <div className="price-action">
          <div className="product-total-price-section">
            <div className="price-with-discount">
              {orderSubscription.oldPrice - orderSubscription.price > 0 && (<span className="discounted-price">{`$${formatPrice((orderSubscription.oldPrice * orderSubscription.quantity))}`}</span>)}

              <span className="total">{`$${formatPrice((orderSubscription.price * orderSubscription.quantity))}`}</span>
            </div>
            <span className="qty-price">{`$${orderSubscription.price} x ${orderSubscription.quantity}`}</span>
          </div>
          <Button className="trash-btn" onClick={() => handleResetOrderSubscription()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
              <path d="M16 4.31605C13.225 4.04105 10.433 3.89905 7.65 3.89905C5.99685 3.89921 4.34475 3.98264 2.7 4.14905L1 4.31605" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5.57812 3.475L5.76113 2.383C5.89413 1.591 5.99412 1 7.40312 1H9.58613C10.9941 1 11.1031 1.625 11.2281 2.392L11.4111 3.475" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.2148 6.94995L13.6728 15.342C13.5808 16.65 13.5058 17.667 11.1808 17.667H5.83078C3.50578 17.667 3.43078 16.65 3.33878 15.342L2.80078 6.94995" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default OrderSubscriptionLineItem;
