import { createSlice } from '@reduxjs/toolkit';
import uniqueString from '../common/util/uniqueString';

const newOrder = {
  totalPrice: 0,
  subTotalPrice: 0,
  tax: 0.00,
  shipping: 0.00,
  discount: 0.00,
  quantity: 0,
  paymentStatus: 'pending',
  orderStatus: 'pending',
  orderDate: null,
  shippingAddressId: null,
  billingAddressId: null,
  userId: null,
  orderItems: [],
  orderSubscription: null,
  subscriptionDeclined: false,
  subscriptionBatchNo: null,
  couponCode: null,
  couponCodePercentage: 0,
  couponCodeDiscount: 0,
};
const calculateTotalPrice = (state) => {
  let subTotalPrice = 0;
  let totalPrice = 0;
  let totalDiscount = 0;
  if (state.order.quantity > 0 && state.order.orderItems.length > 0) {
    subTotalPrice = state.order.orderItems.reduce((subPrice, item) => subPrice + (item.quantity * item.price), 0);
    totalPrice = subTotalPrice;
    // totalDiscount = state.order.orderItems.reduce((subPrice, item) => item.hasOwnProperty('oldPrice') && subPrice + (item.quantity * (item.oldPrice - item.price)), 0);
    if (!state.order.subscriptionDeclined && state.order.orderSubscription && state.order.orderSubscription.planId && state.order.orderSubscription.price) {
      const quantity = state.order.orderItems.reduce((quantity, item) => (quantity + item.quantity), 0);
      state.order.orderSubscription.quantity = quantity;
      // if (state.order.orderSubscription.oldPrice > 0) {
      //   // totalDiscount += (quantity * (state.order.orderSubscription.oldPrice - state.order.orderSubscription.price));
      //   subTotalPrice += (quantity * state.order.orderSubscription.oldPrice);
      // } else {
      //   subTotalPrice += (quantity * state.order.orderSubscription.price);
      // }
      subTotalPrice += (quantity * state.order.orderSubscription.price);
      totalPrice += (quantity * state.order.orderSubscription.price);
    }
  }
  if (state.order.shipping > -1) {
    totalPrice = totalPrice + state.order.shipping + state.order.tax;
  }
  const { couponCodeDiscount } = state.order;
  totalDiscount += couponCodeDiscount;
  state.order = {
    ...state.order,
    subTotalPrice: subTotalPrice.toFixed(2),
    discount: totalDiscount.toFixed(2),
    totalPrice: (totalPrice - couponCodeDiscount).toFixed(2),
  };
};

const saveStoreOrdersValue = (state) => {
  localStorage.setItem('cart-orders', JSON.stringify(state.order));
};

const getStoreOrderValue = () => {
  const storeValue = localStorage.getItem('cart-orders');
  if (storeValue) {
    return JSON.parse(storeValue);
  }
  return newOrder;
};

const { reducer, actions } = createSlice({
  name: 'cart',
  initialState: {
    order: getStoreOrderValue(),
    user: {},
    showMaxQuantityAlert: false,
    MAXALLOWEDCARTQUANTITY: 50,
    promoApplied: false,
  },
  reducers: {
    setPromoApplied(state) {
      state.promoApplied = true;
      saveStoreOrdersValue(state);
    },
    setCartUser(state, action) {
      state.user = action.payload;
      state.order.userId = action.payload.id;
      saveStoreOrdersValue(state);
    },
    setOrderCouponCodeDetails(state, action) {
      const { couponCode, couponCodePercentage, couponCodeDiscount } = action.payload;
      state.order.couponCode = couponCode;
      state.order.couponCodePercentage = couponCodePercentage;
      state.order.couponCodeDiscount = couponCodeDiscount;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    resetOrderCouponCodeDetails(state) {
      state.order.couponCode = null;
      state.order.couponCodePercentage = 0;
      state.order.couponCodeDiscount = 0;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    setShowMaxQuantityAlert(state, action) {
      state.showMaxQuantityAlert = action.payload;
    },
    setOrderSubscription(state, action) {
      const { planId, price, oldPrice } = action.payload;
      state.order.subscriptionBatchNo = uniqueString(10);
      state.order.subscriptionDeclined = false;
      state.order.orderSubscription = { planId, price, oldPrice };
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    resetOrderSubscription(state) {
      state.order.subscriptionBatchNo = uniqueString(10);
      state.order.subscriptionDeclined = false;
      state.order.orderSubscription = null;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    setOrderSubscriptionDeclined(state) {
      state.order.subscriptionDeclined = true;
      state.order.orderSubscription = null;
      state.order.subscriptionBatchNo = null;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    resetOrderSubscriptionDeclined(state) {
      state.order.subscriptionDeclined = false;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    setCartQuantity(state, action) {
      const { selectedItem, quantity } = action.payload;
      let exitingItemIndex = state.order.orderItems.findIndex((item) => item.id === selectedItem.id);
      if (exitingItemIndex === -1) {
        const newQuantity = state.order.orderItems.reduce((quantity, item) => (quantity + item.quantity), 0) + quantity;
        if (newQuantity > 50) {
          return;
        }
        state.order.orderItems.push({
          ...selectedItem,
          quantity,
        });
        exitingItemIndex = state.order.orderItems.length - 1;
      } else {
        const newQuantity = state.order.orderItems.reduce((quantity, item) => (quantity + item.quantity), 0) - state.order.orderItems[exitingItemIndex].quantity + quantity;
        if (newQuantity > 50) {
          return;
        }
        state.order.orderItems[exitingItemIndex].quantity = quantity;
      }
      state.order.quantity = state.order.orderItems.reduce((quantity, item) => (quantity + item.quantity), 0);
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    resetCartQuantity(state) {
      state.order.quantity = 1;
      state.order.orderItems = [];
      state.order.orderSubscription = null;
      state.order.totalPrice = 0;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    setCartAddress(state, action) {
      const { shippingAddressId, billingAddressId } = action.payload;
      if (shippingAddressId !== undefined) state.order.shippingAddressId = shippingAddressId;
      if (billingAddressId !== undefined) state.order.billingAddressId = billingAddressId;
      saveStoreOrdersValue(state);
    },
    resetOrder(state) {
      state.user = {};
      state.order = newOrder;
      saveStoreOrdersValue(state);
    },
    setCartShippingCost(state, action) {
      state.order.shipping = action.payload.shippingCost;
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
    resetCartOrderItem(state, action) {
      const item = action.payload;
      const items = state.order.orderItems.filter((orderItem) => orderItem.id !== item.id);
      const totalQuantity = items.reduce((quantity, item) => (quantity + item.quantity), 0);
      state.order.orderItems = items;
      state.order.quantity = totalQuantity;
      if (items.length === 0 || totalQuantity === 0) {
        state.order = newOrder;
      }
      calculateTotalPrice(state);
      saveStoreOrdersValue(state);
    },
  },
});

export { actions as cartActions };
export { reducer as cartReducer };
