import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import UserMenu from './UserMenu';
import LoggedInUserNotification from '../../../landingPage/Header/LoggedInUser/LoggedInUserNotification';

const LoggedInUser = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClose = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen((prevState) => !prevState);
  const currentUser = useSelector((state) => state.session.user);

  const handleIconClick = (event) => {
    event.stopPropagation();
    toggleMenu();
  };

  return (
    <>
      <LoggedInUserNotification />
      <div className="logged-in-user">
        <Button className="logged-in-btn" onClick={handleIconClick}>
          <img src={currentUser.profileImageUrl ? `${process.env.REACT_APP_BUCKET_URL}/${currentUser.profileImageUrl}` : '/default-dp.png'} alt="user-pic" />
        </Button>
        <UserMenu open={isMenuOpen} handleClose={handleClose} />
      </div>
    </>
  );
};

export default LoggedInUser;
