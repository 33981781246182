import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container } from '@mui/material';
import Logo from './logo/Logo';
import ReachOutToUs from './reachOutToUs/ReactOutToUs';
import PopularLinks from './popularLinks/PopularLinks';
import Contact from './contact/Contact';
import CustomerServiceList from './customerSeriveList/CustomerServiceList';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
const Footer = () => {
  const location = useLocation();
  const showFooter = (location?.pathname !== '/devices' && location?.pathname !== '/geofences');

  return (
    <div className="footer-user" style={showFooter ? {} : { display: 'none' }}>
      {showFooter && (
        <>
          <Container>
            <div className="footer-upper">
              <Logo />
              <div className="footer-upper__content">
                <ReachOutToUs />
                <div className="footer-upper__content__links-container">
                  <PopularLinks scrollToTop={scrollToTop} />
                </div>
                <Contact />
              </div>
            </div>
          </Container>
          <Container>
            <div className="footer-lower">
              <ul className="customner-service-list">
                <li><Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link></li>
                <li><Link to="/terms-and-conditions" onClick={scrollToTop}>Terms and Conditions</Link></li>
                <li><Link to="/shipping" onClick={scrollToTop}>Shipping</Link></li>
                <li><Link to="/return" onClick={scrollToTop}>Returns</Link></li>
              </ul>
              <p className="copyright">© 2024 All Rights Reserved. Patent Pending.*</p>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default Footer;
