import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CartItem from './CartItem';
import { ReactComponent as CrossIcon } from '../../../resources/images/cart-dropdown/close-icon.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import emptyCartImage from '../../../resources/images/cart-dropdown/empty-cart.png';
import OrderSubscriptionLineItem from '../../../CheckoutPage/OrderSubscriptionLineItem';
import { formatPrice } from '../../../common/util/formatter';

const CartDrawer = ({ open, toggleCartOpen }) => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.order);
  const { width } = useWindowDimensions();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleCartOpen}
      sx={{
        '& .MuiPaper-root': {
          width: width > 500 ? '445px' : '100vw',
          marginTop: '0',
          height: '100vh',
        },
      }}
    >
      <div className="cart-dropdown">
        <div className="cart-dropdown__container">
          <div className="cart-dropdown__container__header">
            <p>
              PEEQ Cart
              {' '}
              <span>
                (
                {cart.quantity}
                )
              </span>
            </p>
            <CrossIcon onClick={toggleCartOpen} />
          </div>
          {cart.quantity > 0 ? (
            <>
              <div className="shopping-cart">
                <div className={`cart-dropdown__container__cart ${!cart.quantity > 0 && 'empty-cart-wrapper'}`}>
                  <div className="item-list">
                    {cart.orderItems.map((item) => (
                      <CartItem item={item} key={item.id} />
                    ))}
                    {cart.orderSubscription && (
                      <OrderSubscriptionLineItem />
                    )}
                  </div>
                </div>
              </div>
              <div className="sticky-footer">
                {/* <SubscriptionText /> */}
                <div className="sub-total">
                  <p className="key">Subtotal</p>
                  <p className="value">
                    $
                    {formatPrice(cart.subTotalPrice)}
                  </p>
                </div>
                <Button
                  variant="container"
                  onClick={() => {
                    toggleCartOpen();
                    navigate('/checkout');
                  }}
                >
                  Proceed to Checkout

                </Button>
              </div>

            </>
          ) : (
            <div className="empty-cart">
              <img src={emptyCartImage} alt="empty cart" />
              <p className="title">Your Cart is Empty</p>
              <p className="desc">Looks like you have not added anything in </p>
              <p className="desc">your cart yet. Explore our best product!</p>
              <Button
                variant="container"
                onClick={() => {
                  toggleCartOpen();
                  navigate('/shop');
                }}
              >
                Shop
              </Button>
            </div>
          )}

        </div>
      </div>
    </Drawer>
  );
};

export default CartDrawer;
