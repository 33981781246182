import { useEffect } from 'react';

const LuckyOrangeScript = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the attributes for the script tag
    script.async = true;
    script.defer = true;
    script.src = 'https://tools.luckyorange.com/core/lo.js?site-id=cdf1022f';

    // Append the script element to the <head> element
    document.head.appendChild(script);

    // Cleanup function to remove the script tag when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // Return null since this component doesn't render anything
  return null;
};

export default LuckyOrangeScript;
