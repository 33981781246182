import 'typeface-roboto';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from './store';
import { LocalizationProvider } from './common/components/LocalizationProvider';
import ErrorHandler from './common/components/ErrorHandler';
import theme from './common/theme';
import Navigation from './Navigation';
import preloadImages from './map/core/preloadImages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NativeInterface from './common/components/NativeInterface';
import ServerProvider from './ServerProvider';
import ErrorBoundary from './ErrorBoundary';
import LayoutContainer from './common/components/LayoutContainer';

import './sass/main.scss';
import LuckyOrangeScript from './common/components/LuckyOrangeScript';
import HubSpotScript from './common/components/HubSpotScript';
import GoogleTagManagerScript from './common/components/GoogleTagManagerScript';
// import UserbackWidget from './common/components/UserbackWidget';

const root = createRoot(document.getElementById('root'));

const Index = () => {
  const platform = process.env.REACT_APP_PLATFORM;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  useEffect(() => {
    preloadImages();
  }, []);

  ReactGA.initialize('AW-11467790314', {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });

  return (
    <>
      {(platform === 'customer' && environment === 'production') && (
      <>
        <LuckyOrangeScript />
        <HubSpotScript />
        <GoogleTagManagerScript />
        {/* <UserbackWidget /> */}
      </>
      )}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <ErrorBoundary>
          <Provider store={store}>
            <LocalizationProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <ServerProvider>
                    <BrowserRouter>
                      <LayoutContainer>
                        <Navigation />
                      </LayoutContainer>
                    </BrowserRouter>
                  </ServerProvider>
                  <ErrorHandler />
                  <NativeInterface />
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </Provider>
        </ErrorBoundary>
      </GoogleOAuthProvider>
    </>
  );
};

root.render(<Index />);

// serviceWorkerRegistration.register(`/service-worker.js?v=${new Date().getTime()}`, { scope: '/' });
serviceWorkerRegistration.unregister();
