import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useAdministrator } from '../../../common/util/permissions';

function getClasses(path, link) {
  if (path === link) {
    return 'navbar-list nav-item nav-item-link active';
  } if (path.includes(link) && link !== '/') {
    return 'navbar-list nav-item nav-item-link active';
  }
  return 'navbar-list nav-item nav-item-link';
}
const Navbar = () => {
  const user = useSelector((state) => state.session.user);
  const administrator = useAdministrator();
  const location = useLocation();
  const [showNavLinks, setShowNavLinks] = useState(true);
  const path = location?.pathname;

  useEffect(() => {
    const links = () => !(path === '/devices' || path.includes('/settings') || path.includes('/reports') || path.includes('/replay') || path.includes('/geofences') || path.includes('/orders') || path.includes('/notifications'));
    setShowNavLinks(links);
  }, [path]);

  return (
    <ul className="header-user__main__nav">
      {user && showNavLinks && (
      <li className="nav-item">
        <Link className={getClasses(path, 'devices')} to="/devices">Dashboard</Link>
      </li>
      )}

      {user && administrator && (
      <li className="nav-item">
        <Link className={getClasses(path, 'reports')} to="/reports/route">Reports</Link>
      </li>
      )}
      {showNavLinks && (
        <li className="nav-item">
          <Link className={getClasses(path, 'shop')} to="/shop">Shop</Link>
        </li>
      )}
      {/* <li className="nav-item">
        <Link className={getClasses(path, 'works')} to="/works">How it Works</Link>
      </li> */}
      {showNavLinks && (
      <>
        <li className="nav-item">
          <Link className={getClasses(path, 'about-us')} to="/about-us">About Us</Link>
        </li>
        <li className="nav-item">
          <Link className={getClasses(path, 'support')} to="/support">Support</Link>
        </li>
      </>
      )}
    </ul>
  );
};

export default Navbar;
