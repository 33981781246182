import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Container } from '@mui/material';

import Logo from './logo/Logo';
import LoggedInUser from './loggedInUser/LoggedInUser';
import UserCart from './userCart/UserCart';
import DrawerMenu from './drawerMenu/DrawerMenu';
import Navbar from './Navbar/Navbar';
import { useAdministrator } from '../../common/util/permissions';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.session?.user);
  const administrator = useAdministrator();
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => setOpenDrawer((prevState) => !prevState);
  const path = location?.pathname;

  const showPencilBanner = () => !(user && (path === '/devices' || path.includes('/settings') || path.includes('/reports') || path.includes('/replay') || path.includes('/geofences') || path.includes('/orders')));
  const showHamburger = administrator ? true : showPencilBanner();
  return (
    <header className={`header-user${path === '/shop' ? ' image-header' : ''}${showPencilBanner() ? '' : ' dashboard-header'}`}>
      <Container>
        <div className="header-user__main">
          {showHamburger && (
          <Button className="hamburger-icon" onClick={toggleDrawer}>
            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Vector" d="M2.44485 1.5H9.5625H17.125M2 7H17.125M2 12.5H17.125" stroke="#021021" strokeWidth="2.0625" strokeLinecap="round" />
            </svg>
          </Button>
          )}
          <Navbar />
          <Logo />
          <div className="header-user__main__icons">
            {
              user ?
                <LoggedInUser /> : (
                  <svg onClick={() => navigate('/login')} className="user-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="outline / user-1">
                      <g id="shape">
                        <path d="M17.2119 16.0718H7.73258C5.11494 16.0718 2.99292 18.2558 2.99292 20.95V23.3891H21.9516V20.95C21.9516 18.2558 19.8296 16.0718 17.2119 16.0718Z" stroke="#021021" strokeWidth="1.99565" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.4722 11.1935C15.0899 11.1935 17.2119 9.00945 17.2119 6.31526C17.2119 3.62108 15.0899 1.43701 12.4722 1.43701C9.8546 1.43701 7.73258 3.62108 7.73258 6.31526C7.73258 9.00945 9.8546 11.1935 12.4722 11.1935Z" stroke="#021021" strokeWidth="1.99565" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </g>
                  </svg>
                )
            }
            <UserCart />
          </div>
        </div>
      </Container>
      <DrawerMenu open={openDrawer} toggleDrawer={toggleDrawer} />
    </header>
  );
};

export default Header;
